import styled from 'styled-components';
import { Image } from '@nintendo-of-america/component-library';

export const Root = styled.a`
  grid-column: span 2;
  height: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity 1s ${({ theme }) => theme.movement.easing} 0ms;
  height: 100%;
`;

export const StyledImage = styled(Image)`
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.lightGray2};
  border-bottom: 0;
  overflow: hidden;

  img {
    aspect-ratio: 16 / 9;
  }

  transition: transform ${({ theme }) => theme.movement.duration}
    ${({ theme }) => theme.movement.easing}
    ${({ theme }) => theme.movement.delay};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: translateY(-1rem);
    }
  }

  @media (prefers-reduced-motion) {
    transition: none;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: translateY(0);
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[20]};
  width: 100%;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.color.lightGray2};
  border-top: 0;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  position: relative;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing[24]};
  }

  &::after,
  &::before {
    content: '';
    height: 16px;
    background: ${({ theme }) => theme.color.lightGray2};
    position: absolute;
    width: 1px;
    top: -16px;
  }

  &::after {
    left: -1px;
  }

  &::before {
    right: -1px;
  }
`;

export const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ loaded }) => (loaded ? -1 : 1)};
  opacity: ${({ loaded }) => (loaded ? 0 : 1)};
  transition: opacity 1s ${({ theme }) => theme.movement.easing} 0ms;
`;
