import React from 'react';
import { string, shape } from 'prop-types';
import {
  Heading,
  Button,
  defaultTheme,
} from '@nintendo-of-america/component-library';

import * as S from './PromoGridItem.styles.js';

/** A promo display component to be used within a product grid */
const PromoGridItem = ({ asset, cta, heading }) => {
  return (
    <S.Root href={cta?.url}>
      <S.Wrapper>
        <S.StyledImage
          assetPath={asset}
          crop="pad"
          backgroundFillColor={defaultTheme.color.black}
        />
        <S.Content>
          <Heading variant="h3">{heading}</Heading>
          {cta && <Button size="small">{cta.label}</Button>}
        </S.Content>
      </S.Wrapper>
    </S.Root>
  );
};

PromoGridItem.propTypes = {
  asset: string,
  cta: shape({
    url: string,
    label: string,
  }),
  heading: string,
};

export default PromoGridItem;
