import styled from 'styled-components';
import { SortContainer } from '@nintendo-of-america/search';

export const StyledSortContainer = styled(SortContainer)`
  justify-content: ${({ $breadcrumbs }) =>
    $breadcrumbs ? 'space-between' : 'flex-end'};

  ${({ theme }) => theme.mediaQuery.desktop} {
    align-items: ${({ $breadcrumbs }) => ($breadcrumbs ? 'center' : 'unset')};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing[32]};
`;

export const LegalText = styled.div`
  margin-top: ${({ theme }) => theme.spacing[32]};
  text-align: center;

  ${({ theme }) => theme.mediaQuery.tablet} {
    margin-top: ${({ theme }) => theme.spacing[48]};
  }
`;

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.primary};
  padding-top: ${({ theme }) => theme.spacing[64]};
  padding-bottom: ${({ theme }) => theme.spacing[64]};
`;
