import styled from 'styled-components';
import {
  ChevronUpIcon as BaseChevronUpIcon,
  Button,
} from '@nintendo-of-america/component-library';
import {
  CONSTRAINED_MAX_WIDTH_SMALL,
  CONSTRAINED_WIDTH,
} from '@local/components/constrained';

export const DRAWER_DESKTOP_HEIGHT = '64px';
export const DRAWER_MOBILE_HEIGHT = '110px';
export const DRAWER_OFFSET = 24;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 96%;

  ${({ theme }) => theme.mediaQuery.desktop} {
    width: ${CONSTRAINED_WIDTH};
    max-width: ${CONSTRAINED_MAX_WIDTH_SMALL};
  }
`;

export const ActiveControlContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.75rem;
`;

export const PillContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

export const ActivePill = styled(Button)`
  width: auto;
  min-width: 50%;
  max-width: 75%;

  & > span {
    max-width: 100%;
  }

  ${({ theme }) => theme.mediaQuery.desktop} {
    min-width: unset;
  }
`;

export const Pill = styled(Button)`
  max-width: 100%;

  & > span {
    max-width: 100%;
  }
`;

export const Toggle = styled(Button)`
  width: auto;
`;

export const Label = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonContainer = styled.div`
  max-width: 100%;
  margin-left: ${({ theme }) => theme.spacing[12]};
  margin-right: ${({ theme }) => theme.spacing[12]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const IconSpacer = styled.div`
  position: relative;
  width: ${({ theme }) => theme.spacing[8]};
`;

export const ChevronToggleIcon = styled(BaseChevronUpIcon)`
  position: absolute;
  transform: rotate(${({ $isOpen }) => ($isOpen ? '180deg' : '0deg')});
  left: ${({ theme }) => theme.pxToRem(10)};
  transition: transform 150ms ease-in-out;

  ${({ theme }) => theme.mediaQuery.desktop} {
    transform: rotate(${({ $isOpen }) => ($isOpen ? '0deg' : '180deg')});
  }
`;
