import { useState, useCallback, useMemo } from 'react';
import {
  CUSTOM_DRAWER_TARGET,
  NAV_HEIGHT,
} from '@nintendo-of-america/global-nav';
import { defaultTheme } from '@nintendo-of-america/component-library';
import useScrollingDrawer from '@local/lib/hooks/useScrollingDrawer';
import ActiveCollectionSticky, {
  DRAWER_DESKTOP_HEIGHT,
  DRAWER_MOBILE_HEIGHT,
  DRAWER_OFFSET,
} from './ActiveCollectionSticky';

const useStickyCollectionNav = (config) => {
  const {
    isDesktop,
    scrollToRef,
    scrollToElementID,
    enableStickyCollectionNav,
    pillFarm,
  } = config || {};

  const [isStickyCollectionOpen, setIsStickyCollectionOpen] = useState(false);
  const [stickyCollectionOffset, setStickyCollectionOffset] = useState(0);

  const handleStickyCollectionToggle = useCallback((offset) => {
    setStickyCollectionOffset(offset);
    setIsStickyCollectionOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const drawerIds = useMemo(() => [scrollToElementID], [scrollToElementID]);
  const scrollingOptions = useMemo(
    () => ({
      viewPortTopOffset: isDesktop ? NAV_HEIGHT.DESKTOP : 0,
      disableDrawers: !enableStickyCollectionNav,
    }),
    [isDesktop, enableStickyCollectionNav]
  );

  const stickyCtaDrawerRefs = useMemo(
    () => ({
      [scrollToElementID]: {
        ref: scrollToRef,
      },
    }),
    [scrollToRef, scrollToElementID]
  );

  const stickyHeight = useMemo(() => {
    const offset = isStickyCollectionOpen
      ? stickyCollectionOffset + DRAWER_OFFSET
      : 0;

    return isDesktop
      ? `${parseInt(DRAWER_DESKTOP_HEIGHT) + offset}px`
      : `${parseInt(DRAWER_MOBILE_HEIGHT) + offset}px`;
  }, [isDesktop, isStickyCollectionOpen, stickyCollectionOffset]);

  const stickyCtaDrawers = useMemo(
    () => ({
      [scrollToElementID]: {
        drawer: {
          height: stickyHeight,
          bgColor: defaultTheme.color.lightGray3,
          target: CUSTOM_DRAWER_TARGET.TOP_DRAWER,
          enableScrim: isStickyCollectionOpen,
          onScrimClick: handleStickyCollectionToggle,
          content: (
            <ActiveCollectionSticky
              isOpen={isStickyCollectionOpen}
              pillFarm={pillFarm}
              onToggle={handleStickyCollectionToggle}
            />
          ),
        },
      },
    }),
    [
      stickyHeight,
      scrollToElementID,
      pillFarm,
      isStickyCollectionOpen,
      handleStickyCollectionToggle,
    ]
  );

  useScrollingDrawer(
    drawerIds,
    stickyCtaDrawerRefs,
    stickyCtaDrawers,
    scrollingOptions
  );
};

export default useStickyCollectionNav;
