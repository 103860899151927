import dynamic from 'next/dynamic';

export const mushroomKingdom = dynamic(
  () => import('./mushroom-kingdom/mushroom-kingdom')
);

export const plmpStandard = dynamic(
  () => import('./plmp-standard/plmp-standard')
);

export const birthday = dynamic(() => import('./birthday/birthday.js'));

export const peon = dynamic(() => import('./peon/peon.js'));

export const indieWorld = dynamic(() => import('./indie-world/indie-world.js'));
